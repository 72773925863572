@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
/* rgb(15, 23, 42) */
:root {
  --color-bg: #1f1f38;
  /* --color--bg: rgb(36, 34, 34); */
  /* --color--bg: rgb(15, 23, 42); */
  --color-bg-variant: #0e5b6e;
  /* --color-primary: #4db5ff; */
  --color-primary: rgb(10, 212, 248);
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  /* --color-primary-variant: grey; */
  /* --color-white: #09f0d1; */
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-dark: #000000;

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  /* background-image: url();  add small picture here for texture*/ 
}


/* General styles */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

.test {
  color: red;
}

section {
  margin-top: 8rem;
  /* height: 100vh; */
}

section > h2, section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  margin-right: 15px;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  font-weight: 500;
}

.btn:hover {
  background: var(--color-whiter);
  background-color: rgb(10, 212, 248, 0.3);
  /* color: var(--color--bg); */
  color: white;
  /* border-color: white; */
  border-color: transparent;
  border-radius: 10px;
  
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}


/* Media queries */

/* ### Medium Devices ### */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  .section {
    margin-top: 6rem;
  }
}

/* ### Small Devices ### */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  .section > h2{
    margin-bottom: 2rem;
  }
}